<template>
  <div v-if="init" class="products">
    <h1>Products GS1</h1>

    <!-- <div class="controls">
      <div class="search">
        <input type="text" placeholder="Search" v-model="search" />
      </div>
      <div class="meta">
        <span
          >Showing: {{ getProductsBySearch(search).length }} /
          {{ productCount }}
        </span>
      </div>
    </div> -->

    <div class="admin-table products-gs1-table">
      <div class="table-header">
        <span class="prodno label">Visma ID</span>
        <span class="name label">Name(Visma)</span>
        <span class="allergen-contains label">Allergen contains</span>
        <span class="allergen-may-contain label">Allergen may contain</span>
        <span class="origin label">Origin country</span>
        <span class="ingredients label">Ingredients</span>
        <span class="nutrition label">Nutrition</span>
      </div>
      <div v-for="prod in products" :key="prod.id">
        <div v-if="getProductByVismaId(prod.id) !== undefined">
          <span class="prodno">{{ prod.id }}</span>
          <span class="name">{{ getProductByVismaId(prod.id).name }}</span>
          <span class="allergen-contains">{{ prod.allergenContains }}</span>
          <span class="allergen-may-contain">{{
            prod.allergenMayContain
          }}</span>
          <span class="origin">{{ prod.countryOfOrigin }}</span>
          <span
            class="ingredients"
            v-html="formatIngredients(prod.ingredients)"
          ></span>
          <span
            class="nutrition"
            v-html="nutritionString(prod.nutritionalInfo)"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      createEmail: "",
      loading: false,
      message: "",
      search: "",
    };
  },
  mounted() {},
  async created() {
    await this.$store.dispatch("productsGS1/bindProducts");
    this.$store.commit("productsGS1/setInit", true);
    console.log(this.products);
  },
  beforeDestroy() {
    this.$store.dispatch("productsGS1/unbindProducts");
    this.$store.commit("productsGS1/setInit", false);
  },
  computed: {
    ...mapState({
      products: (state) => state.productsGS1.products,
      init: (state) => state.productsGS1.init,
    }),
    ...mapGetters("products", ["getProductByVismaId", "productCount"]),
  },
  methods: {
    formatIngredients: function (ingredients) {
      let string = "<p>";
      if (ingredients !== null && ingredients.constructor === Array) {
        console.log(ingredients);
        ingredients.forEach((ingredient) => {
          if (ingredient.bold == true) {
            string += "<b>" + ingredient.value + "</b>";
          } else {
            string += ingredient.value;
          }
        });
      }

      string += "</p>";

      return string;
    },
    allergenString: function (allergens) {
      if (allergens == null || allergens.length == 0) {
        return "NO INFO";
      }
      let returnString = "";

      allergens.forEach((allergen) => {
        returnString +=
          allergen.name + ": " + allergen.levelOfContainment + "<br>";
      });
      return returnString;
    },
    nutritionString: function (nutrtitionalInfo) {
      if (
        nutrtitionalInfo == null ||
        nutrtitionalInfo.nutrients == null ||
        nutrtitionalInfo.nutrients.length == 0
      ) {
        return "NO INFO";
      }

      let returnString = "";

      nutrtitionalInfo.nutrients.forEach((nutrient) => {
        let quantityString = "";
        nutrient.quantityContained.forEach((quantity) => {
          quantityString += quantity.value + ": " + quantity.name + " ";
        });

        returnString += nutrient.name + ": " + quantityString + "<br>";
      });

      return returnString;
    },
  },
};
</script>
